import { cancelPaypalPayment, cancelPaypalSubscriptionUrl, createPaypalSubscription, createStripeCheckoutSession, endAuthSession, fetchUser, redirectUri, sessionUser, subscriptionDetailUrl, textGenerationBase, textGenerationBaseFollowup, textGenerationMultiturn, stripeCheckoutRetrieveSessionURL, gptServiceUrl, gptRefineServiceUrl } from "./urls.prod"
import { cancelPaypalPaymentUAT, cancelPaypalSubscriptionUrlUAT, createPaypalSubscriptionUAT, createStripeCheckoutSessionUAT, endAuthSessionUAT, fetchUserUAT, getSubscriptionDetailUrlUAT, gptRefineServiceUrlUAT, gptServiceUrlUAT, redirectUriUAT, sessionUserUAT, stripeCheckoutRetrieveSessionUATURL, subscriptionDetailUrlUAT, textGenerationBaseFollowupUAT, textGenerationBaseUAT, textGenerationMultiturnUAT } from "./urls.uat"

//text-generation
export const getTextGenerationBaseApi = () => window.location.href.includes("localhost") ? textGenerationBaseUAT: textGenerationBase;
export const getTextGenerationMultiturnApi = () => window.location.href.includes("localhost") ? textGenerationMultiturnUAT: textGenerationMultiturn;
export const getTextGenerationFollowupApi = () => window.location.href.includes("localhost") ? textGenerationBaseFollowupUAT: textGenerationBaseFollowup;

//text-generation service helpers
export const getTextGenerationUrl = () => window.location.href.includes("localhost") ? gptServiceUrl: gptServiceUrl;

//refine-generation-service
export const getTextRefinementUrl = () => window.location.href.includes("location") ? gptRefineServiceUrlUAT : gptRefineServiceUrl;

//authentication
export const getRedirectUri = () => window.location.href.includes("localhost") ? redirectUriUAT : redirectUri;
export const getEndAuthSessionApi = () => window.location.href.includes("localhost") ? endAuthSessionUAT: endAuthSession;
export const getSessionUser = () => window.location.href.includes("localhost") ? sessionUserUAT : sessionUser;
export const fetchUserFromCode = () => window.location.href.includes("localhost") ? fetchUserUAT: fetchUser;

//feed https://infolitcontentservice.azurewebsites.net/
 //export const getFeed = () => window.location.href.includes("localhost") ? "http://localhost:8082/" : "https://infolitcontentservice.azurewebsites.net/"
 export const getFeed = () => window.location.href.includes("localhost") ? "http://localhost:8082/" : "https://infolitcontentservice.azurewebsites.net/"
 //export const getFeed = () => window.location.href.includes("localhost") ? "https://infolit.azurewebsites.net/" : "https://infolitcontentservice.azurewebsites.net/"

//basic 
export const getBasicChatLanguageModel = () => window.location.href.includes("localhost") ? "https://infolitsearchservice.azurewebsites.net/gpt-service-basic-chat-language-model" :"https://infolitsearchservice.azurewebsites.net/gpt-service-basic-chat-language-model" 
//export const getBasicChatLanguageModel = () => window.location.href.includes("localhost") ? "http://localhost:8088/gpt-service-basic-chat-language-model":"https://infolitsearchservice.azurewebsites.net/gpt-service-basic-chat-language-model"
 export const getBasicChatLanguageModelAstro = () => window.location.href.includes("localhost") ? "http://localhost:8088/gpt-service-basic-chat-language-model-astro":"https://infolitsearchservice.azurewebsites.net/gpt-service-basic-chat-language-model-astro"
 //export const getBasicChatLanguageModel = () => "https://infolitsearchservice.azurewebsites.net/gpt-service-basic-chat-language-model"
export const getSearchRAGModel = () => window.location.href.includes("localhost") ? "http://localhost:8088/gpt-service-search-rag-model":"http://localhost:8088/gpt-service-search-rag-model"
export const getWritingAssistantModel = () => window.location.href.includes("localhost") ? "http://localhost:8088/writing-assistant":"https://infolitsearchservice.azurewebsites.net/writing-assistant"

//payments
export const getCancelPaypalSubscriptionUrl = () => window.location.href.includes("localhost") ? cancelPaypalSubscriptionUrlUAT: cancelPaypalSubscriptionUrl;
export const getCreatePaypalSubscriptionUrl = () => window.location.href.includes("localhost") ? createPaypalSubscriptionUAT : createPaypalSubscription;
export const getSubscriptionDetailUrl = () => window.location.href.includes("localhost") ? subscriptionDetailUrlUAT: subscriptionDetailUrl;

//stripe intergration
export const getStripeCheckoutUrl = () => window.location.href.includes("localhost") ? createStripeCheckoutSessionUAT: createStripeCheckoutSession;
export const getStripeCheckoutRetrieveSessionUrl = () => window.location.href.includes("localhost") ? stripeCheckoutRetrieveSessionUATURL: stripeCheckoutRetrieveSessionURL;