import { useEffect, useState } from 'react';
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import "./BasicChat.css"

const MobileDateComponent = () => {
  // const [value, setValue] = useState(new Date());
  //dispatch({type: actionTypes.SET_USER_DETAIL, userDetail:{...userDetail, "dob": `${dateValue.date()}/${dateValue.month()}/${dateValue.year()}`, "tob":`${value.getHours()}::${value.getMinutes()}`}});
  const [dateValue, setDateValue] = useState(dayjs('2024-03-28'));
  const [_, dispatch] = useStateValue();

  const map = (monthNum) => {
    if(monthNum == 0) return "01";
    if(monthNum == 1) return "02";
    if(monthNum == 2) return "03";
    if(monthNum == 3) return "04";
    if(monthNum == 4) return "05";
    if(monthNum == 5) return "06";
    if(monthNum == 6) return "07";
    if(monthNum == 7) return "08";
    if(monthNum == 8) return "09";
    if(monthNum == 9) return "10";
    if(monthNum == 10) return "11";
    if(monthNum == 11) return "21";
  }

  useEffect(()=>{
    console.log(`In MobileDateComponent`);
    // console.log(dateValue.date());//20
    // console.log(dateValue.month());//
    // console.log(dateValue.year());
    // dispatch({type: actionTypes.SET_USER_DETAIL, userDetail:{...userDetail, "dob": `${dateValue.date()}/${map(dateValue.month())}/${dateValue.year()}`}});
    dispatch({ type: actionTypes.SET_USER_DOB, dob: `${dateValue.date()}/${map(dateValue.month())}/${dateValue.year()}` });
  },[dateValue])

  return (
    <div>
         <b>Enter Birth Date </b><br/>  <br/>
        <MobileDatePicker value={dateValue} onChange={(newDateValue) => setDateValue(newDateValue)} />
    </div>
  );
}

export default MobileDateComponent;