import React from "react";
import "./BasicChat.css";
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import SearchIcon from '@mui/icons-material/Search';
import StoreIcon from '@mui/icons-material/Store';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RadioIcon from '@mui/icons-material/Radio';
import FeedIcon from '@mui/icons-material/Feed';
import { actionTypes } from "../statemanagement/reducer";
import GooglePlayBadge from "../othercomponents/GooglePlayBadge";
import { useStateValue } from "../statemanagement/StateProvider";

const AppFooter = ({ showSearch = true, showHome = true, shareUrl = "", showPost = true, showDiscover = true, showNumerology = false, showWrite=false }) => {
    const location = useNavigate();
    const [{}, dispatch] = useStateValue();
    return (
        <div className="footer" style={{ background: "white", display: "flex", position: "fixed", bottom: "0", width: "100%", flexDirection: "column", borderRadius:"10px 10px 0px 0px" }}>
            <div className="footer__inner" style={{ margin: "auto", alignItems: "center", display: "flex", paddingTop: "0.3rem", paddingBottom: "0.3rem", color: "white", fontSize: "13px", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>

                



                <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        try {
                            // const url = "https://tigerchat.co.in";
                            const url = "https://play.google.com/store/apps/details?id=com.tigerchat.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                            const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
                            window.open(whatsappUrl, '_blank');
                            window.gtag('event', 'Share');
                        } catch (_) {
                            console.error("Failed to Copy");
                        }
                    }}>
                    <img src="/icons/whatsapp.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>Share</div>
                </Button>

                {(showSearch || showHome) && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        dispatch({type: actionTypes.SET_WEB_ACCESS, webaccess: false});
                        location("/search");
                    }}>
                    <img src="/icons/search.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>Chat with AI</div>
                </Button>}

                

                {/* {showDiscover && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        //dispatch({type: actionTypes.UPDATE_HEADLINES, headlines:[]})
                        dispatch({type: actionTypes.UPDATE_HEADLINES, headlines: []})
                        location("/");
                    }}>
                    <img src="/icons/content.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>AI Feed</div>
                </Button>} */}
                {/* <GooglePlayBadge/> */}
                {showNumerology && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        dispatch({type: actionTypes.SET_SEARCH_TERM, term:"astro:"})
                        location("/astrobot");
                        
                    }}>
                    <img src="/icons/numerology.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>Astrology</div>
                </Button>}

                {/* {showWrite && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        location("/write")
                    }}>
                    <img src="/icons/pencil.png" alt="WhatsApp" style={{ width: "28px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>AI Writer</div>
                </Button>} */}

                {false && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        location("/business")
                    }}>
                    <img src="/icons/business.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>Business</div>
                </Button>}


            </div>
            {/* <div className="footer__inner" style={{ margin: "auto", alignItems: "center", display: "flex",  color: "white", fontSize: "13px", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>

                {(showSearch || showHome) && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        location("/search")
                    }}>
                    <img src="/icons/search.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>Ask AI</div>
                </Button>}



                <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        try {
                            // const url = "https://tigerchat.co.in";
                            const url = "https://play.google.com/store/apps/details?id=com.tigerchat.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                            const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
                            window.open(whatsappUrl, '_blank');
                            window.gtag('event', 'Share');
                        } catch (_) {
                            console.error("Failed to Copy");
                        }
                    }}>
                    <img src="/icons/whatsapp.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>Share</div>
                </Button>

                {showDiscover && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        location("/")
                    }}>
                    <img src="/icons/content.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>AI Feed</div>
                </Button>}

                {true && <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", // Use flex layout
                        flexDirection: "column", // Arrange children in a column
                        alignItems: "center", // Center-align items horizontally
                        justifyContent: "center", // Center-align items vertically
                    }}
                    onClick={async _ => {
                        location("/business")
                    }}>
                    <img src="/icons/business.png" alt="WhatsApp" style={{ width: "25px", textDecoration: "none" }} />
                    <div style={{ fontSize: "13px", opacity: "0.7", marginTop: "3px" }}>Business</div>
                </Button>}


            </div> */}


        </div>
    );
}

export default AppFooter;