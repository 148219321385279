import './App.css';
import Results from './pages/Results';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BasicChat from './pages/BasicChat';
import PrivacyPolicy from "./pages/Privacy";
import Docs from "./pages/Docs";
import { loadStripe } from '@stripe/stripe-js';
import { Login } from './pages/Login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CustomerSupport from './pages/CustomerSupport';
import TermsOfService from './pages/TermsOfService';
import Cancellation from './pages/Cancellation';
import Custom from './pages/Custom';
import TigerStore from './pages/TigerStore';
import Post from './pages/Post';
import Feed from "./pages/Feed"
import ContentFeed from './pages/ContentFeed';
import Business from './pages/Business';
import Numerology from './pages/Numerology';
import Write from './pages/Write';
import Astrobot from './pages/Astrobot';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function App() {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="app">
              <Router>
                    <Routes>
                      <Route exact path="login" element={<Login />} />
                      <Route exact path="/query" element={<Results />} />
                      <Route exact path="/" element={<BasicChat />} />
                      <Route exact path="/write" element={<Write />} />
                      <Route exact path="/docs" element={<Docs />} />
                      <Route exact path="/privacy" element={<PrivacyPolicy />} />
                      <Route exact path="/customer-support" element={<CustomerSupport />} />
                      <Route exact path="/terms-of-service" element={<TermsOfService />} />
                      <Route exact path="/cancellation" element={<Cancellation />} />
                      <Route exact path="/custom" element={<Custom />} />
                      <Route exact path='/store' element={<TigerStore/>} />
                      <Route exact path='/post' element={<Post/>} />
                      <Route exact path='/feed' element={<Feed/>} />
                      {/* <Route exact path='/' element={<ContentFeed/>} />
                      <Route exact path='/business' element={<Business/>} /> */}
                      <Route exact path='/numerology' element={<Numerology/>} />
                      <Route exact path='/astrobot' element={<Astrobot/>} />
                    </Routes>
              </Router>
      </div>
      </LocalizationProvider>
   
      
   
    
  );
}

export default App;

