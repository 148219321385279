import React from "react";
import "./BasicChat.css";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../statemanagement/StateProvider";
import "./BasicChat.css"; //should be moved to Header.css
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Button } from "@mui/material";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import FeedIcon from '@mui/icons-material/Feed';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { actionTypes } from "../statemanagement/reducer";

//AuthWrapperHook should also be pushed here
const Header = ({ showLine = true, showLogin = true }) => {
    const [{ user }, dispatch] = useStateValue(); //header will always have access to the user object from state
    const navigate = useNavigate(); //navigation to other pages within the SPA

    const SignInRouteClicked = (_) => {
        window.gtag('event', 'SignInRoute');
        navigate("/login")
    }

    const AccountRouteClicked = (_) => {
        window.gtag('event', 'AccountRouteClicked');
        navigate("/login")
    }

    return (<div className="container_header" >
        <div className="container_header_banner" style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex", width: "50vw" }} onClick={_ => { navigate("/"); dispatch({ type: actionTypes.UPDATE_HEADLINES, headlines: [] }); }}>
                <img src="/icons/numerology.png" width={"38px"} style={{ paddingRight: "2%" }} />
                <h1 style={{ fontSize: "30px", fontWeight: 500, marginLeft: "2%", opacity: "0.9" }}>AI Astro</h1>
            </div>
            <div style={{ display: "flex", fontSize: "small" }}>
                <div style={{ marginRight: "5%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_) => navigate("/privacy")}>Privacy</a></div>
                <div style={{ marginLeft: "5%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_) => navigate("/customer-support")}>Support</a></div>
            </div>
            {/* {!user && showLogin && <Button style={{ fontSize: "0.74em", textTransform: "inherit", background: "black", opacity: "0.8", borderRadius: "18px" }} variant="contained" onClick={SignInRouteClicked}>Sign In</Button>}
            {!!user && <AccountCircleIcon style={{ fontSize: "xx-large" }} onClick={AccountRouteClicked} />} */}
        </div>
        {showLine && <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} />}
    </div>);
}

export default Header;



// const Header = (showLine = true) => {

//     const navigate = useNavigate();

//     return (<div className="container_header">
//         <div className="container_header_banner" style={{ justifyContent: "space-between" }}>
//             <div style={{ display: "flex" }} onClick={_ => navigate("/")}>
//                 <AllInclusiveIcon style={{ width: "35px", height: "35px" }} />
//                 <h1 style={{ fontWeight: 500, marginLeft: "2%", opacity: "0.9", display: "flex" }}>ChatDragon.ai</h1>
//             </div>
//         </div>
//         {showLine && <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} />}
//     </div>);
// }

// export default Header;