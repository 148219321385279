import React from "react";
import Search from '../components/Search';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import "./BasicChat.css"
import { TypeAnimation } from "react-type-animation";
import { modes } from "../mock/PromptAssistanceExamples";
import { useState, useEffect } from "react";
import Header from "./Header";
import AppFooter from "./AppFooter";
import AudioComponent from "../components/AudioComponent";


function Write() {
    const [index, setIndex] = useState(0);
    const [{}, dispatch] = useStateValue();


    useEffect(() => {
        dispatch({ type: actionTypes.SET_STREAMING, streaming: false });
        const interval = setInterval(() => {
            setIndex(u => (u + 1) % modes.length)
        }, 4100);
        return () => clearInterval(interval);
    }, [])

    // useEffect(()=>{
    //     dispatch({type:actionTypes.SET_MODE, mode:"write"});
    //     dispatch({type: actionTypes.SET_WEB_ACCESS, webaccess:false});
    // },[])

    return (
        <div>
           
            <div className="container">
                <Header showLogin={true} />
                
                {/* <br/>
                <AudioComponent/> */}
                <div className="container_body" style={{paddingTop: "5%"}}>
                    <div className="container_copy">
                        {/* <div wrapper="span" speed={25} style={{ fontSize: "1.0em", lineHeight: "2.1rem", display: "inline-block", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} repeat={0}>
                        AI Made for India | एआई भारत के लिए निर्मित
                        </div> */}
                           <TypeAnimation sequence={[
                            
                            "Write with AI",


                        ]} wrapper="span" speed={2} style={{ fontSize: "1.1em", lineHeight: "2.1rem", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} />
                    </div>
                    
                
                
                    <Search write={true} home={true} showButtons={true} mode={"landing"} placeholder={""} route={true} style={{ fontWeight: 400 }} />
                    
                </div>
            </div>
            <AppFooter showHome={false} showSearch={false} showNumerology={true} showWrite={true} />
        </div>
    );
}

export default Write;