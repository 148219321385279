import React, { useEffect, useState } from 'react';
import AudioShort from '../components/AudioShort';
import "./BasicChat.css"
import Header from "./Header";
import AppFooter from "./AppFooter";
import AudioComponent from "../components/AudioComponent";
import SnapCard from "../components/SnapCard"
import { useStateValue } from "../statemanagement/StateProvider"; 
import { actionTypes } from "../statemanagement/reducer";

const Feed = () => {
  const [currentPlaying, setCurrentPlaying] = useState(null);
  const [{headlines}, dispatch] = useStateValue(); 

  
  // useEffect(() => {
  //     const observer = new IntersectionObserver((entries) => {
  //         entries.forEach((entry) => {
  //             if (entry.isIntersecting) {
  //                 setCurrentPlaying(entry.target.id);
  //             }
  //         });
  //     }, { threshold: 0.5 }); // Adjust threshold as needed

  //     document.querySelectorAll('.short').forEach((item) => {
  //         observer.observe(item);
  //     });

  //     return () => observer.disconnect();
  // }, []);

  // const audioFiles = ["audio.mp3", "1.mp3", "2.mp3", "3.mp3", "4.mp3", "5.mp3", "6.mp3", "7.mp3", "8.mp3", "9.mp3", "10.mp3", "11.mp3", "12.mp3", "13.mp3", "14.mp3"];
  // const audioImagePairs = [
  //   { audio: "audio.mp3", image: "/rjp.webp" },
  //   { audio: "1.mp3", image: "https://www.hindustantimes.com/ht-img/img/2024/02/29/1600x900/ANI-20240120435-0_1709222890645_1709222953583.jpg" },
  //   { audio: "2.mp3", image: "https://c.ndtvimg.com/2023-09/7csiq6p8_rohit-sharma-virat-kohli-afp_625x300_28_September_23.jpg?im=FitAndFill,algorithm=dnn,width=1200,height=675" },
  //   { audio: "3.mp3", image: "https://static.india.com/wp-content/uploads/2024/02/MixCollage-29-Feb-2024-11-25-PM-3899.jpg" },
  //   { audio: "4.mp3", image: "https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BB1j7sFh.img?w=1280&h=720&m=4&q=70" },
  //   { audio: "5.mp3", image: "https://static.toiimg.com/thumb/msid-108114482,width-1070,height-580,imgsize-40446,resizemode-75,overlay-toi_sw,pt-32,y_pad-40/photo.jpg" },
  //   { audio: "6.mp3", image: "https://timesofindia.indiatimes.com/photo/msid-108115121,imgsize-61310.cms" },
  //   { audio: "7.mp3", image: "https://cdn.wionews.com/sites/default/files/2024/03/01/415411-bill-gates-with-pm-modi.png" },
  //   { audio: "8.mp3", image: "https://i.postimg.cc/Dw3CzK3j/gitaAI.webp" },
  //   { audio: "9.mp3", image: "https://images.cnbctv18.com/wp-content/uploads/2024/02/anant-ambani-4-1019x573.jpg" },
  //   { audio: "10.mp3", image: "https://static.toiimg.com/thumb/msid-108158801,width-1070,height-580,imgsize-66572,resizemode-75,overlay-toi_sw,pt-32,y_pad-40/photo.jpg" },
  //   { audio: "11.mp3", image: "https://cdn.dnaindia.com/sites/default/files/styles/full/public/2024/03/01/2627586-untitled-design-2024-03-01t125254.533.png" },
  //   { audio: "12.mp3", image: "https://images.thequint.com/thequint%2F2024-03%2Ffdd44f6a-6c20-475e-bbd4-0a82f6a3172b%2FScreenshot__1073_.png" },
  //   { audio: "13.mp3", image: "https://newsmeter.in/h-upload/2024/03/03/365168-picsart24-03-0315-14-12-326.jpg" },
  //   { audio: "14.mp3", image: "https://www.masala.com/cloud/2024/03/06/kangana.png" },
  // ];

  return (
    <div>
      <Header showLogin={true}/>
        <div className="app__shorts" style={{ overflowY: 'scroll', scrollSnapType: 'y mandatory', height: '80vh' }}>
            <div className="short" style={{
                backgroundColor: 'gold',
                height: '100%',
                position: 'relative',
                scrollSnapAlign: 'start',
                width: '100%'
            }}>
                {/* <div style={{height:"100vh"}}>short-1</div> */}
                <SnapCard  source={"India.com on MSN.com"}
                handleClick={(name)=>{}}
                name={"Shaitaan AI Review (3/5)"} body={""} 
                image={"https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BB1jFrE8.img?w=700&h=472&m=4&q=91"} 
                id={"20"} voice={"AI Aaryan, Majestic & Deep (Hindi)"} 
                />

            </div>
            <div className="short" style={{
                backgroundColor: 'green',
                height: '100%',
                position: 'relative',
                scrollSnapAlign: 'start',
                width: '100%'
            }}>
               <SnapCard  source={"India.com on MSN.com"}
                handleClick={(name)=>{}}
                name={"Shaitaan AI Review (3/5)"} body={""} 
                image={"https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BB1jFrE8.img?w=700&h=472&m=4&q=91"} 
                id={"20"} voice={"AI Aaryan, Majestic & Deep (Hindi)"} 
                />
                {/* <div style={{height:"100vh"}}>short-2</div> */}
            </div>
       </div>
       <AppFooter/>
  </div>

  );
};

export default Feed;
