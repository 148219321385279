import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ComboBox({trigger}) {
  // State to hold the input value
  const [inputValue, setInputValue] = React.useState('');

  return (
    <Autocomplete width={"100% !important"}
      onChange={(event, newValue) => {
        if(!newValue) return;
        // newValue is the selected item from the dropdown
        console.log('Selected item:', newValue.label);
        trigger(newValue.label);
      }}
      onInputChange={(event, newInputValue) => {
        // Update the inputValue state whenever the input changes
        setInputValue(newInputValue);
      }}
      onKeyDown={(e) => {
        // Check if the Enter key was pressed
        if (e.key === 'Enter') {
          // Log the current input value when Enter is pressed
          console.log('Entered value:', inputValue);
          trigger(inputValue);
        }
      }}
      disablePortal
      id="combo-box-demo"
      options={top100Themes}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="e.g. Mumbai's Marine Drive at night" />}
    />
  );
}


// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Themes = [
    { label: 'Indian Village Life' },
    { label: 'The Milky Way as seen from the Himalayas' },
    { label: 'Sunrise over the Himalayas' },
    { label: 'Bengal Tiger in the Sundarbans' },
    { label: 'Traditional Indian Markets' },
    { label: 'Space Exploration: Mars Mission' },
    { label: 'The Ganges River Flowing through Varanasi' },
    { label: 'Diwali Celebrations in an Indian City' },
    { label: 'Yoga Session in Rishikesh' },
    { label: 'Rajasthani Folk Dancers' },
    { label: 'Kerala Backwaters' },
    { label: 'Chandrayaan Spacecraft on the Moon' },
    { label: 'Taj Mahal at Sunrise' },
    { label: 'Indian Street Food Market' },
    { label: 'Mumbai’s Marine Drive at Night' },
    { label: 'Camel Fair in Pushkar' },
    { label: 'Kolkata’s Howrah Bridge' },
    { label: 'Leopard in the Western Ghats' },
    { label: 'Paddy Fields of Punjab' },
    { label: 'Tea Gardens of Darjeeling' },
    { label: 'Monsoon in the Western Ghats' },
    { label: 'Himalayan Monastery' },
    { label: 'Desert Safari in the Thar Desert' },
    { label: 'Indian Classical Music Concert' },
    { label: 'The Golden Temple of Amritsar' },
    { label: 'Spices of India' },
    { label: 'Elephant Festival in Jaipur' },
    { label: 'ISRO Satellite Launch' },
    { label: 'Houseboats in Srinagar' },
    { label: 'Mysore Palace Illuminated' },
    { label: 'Fishing Boats in Goa' },
    { label: 'Kumbh Mela Gathering' },
    { label: 'Cherry Blossoms in Shillong' },
    { label: 'Snow Leopard in Ladakh' },
    { label: 'Rann of Kutch Salt Desert' },
    { label: 'Jodhpur’s Blue Houses' },
    { label: 'Indian Railways: Journey through the Countryside' },
    { label: 'Sikkim’s Buddhist Festivals' },
    { label: 'Wildlife of Sundarbans' },
    { label: 'Peacock Dancing in the Monsoon' },
    { label: 'Old Delhi’s Historical Architecture' },
    { label: 'Indian Wedding Ceremony' },
    { label: 'Varanasi’s Evening Aarti' },
    { label: 'Lotus Temple in New Delhi' },
    { label: 'Holi Festival Celebrations' },
    { label: 'Indian National Cricket Team in Action' },
    { label: 'Munnar’s Rolling Hills' },
    { label: 'Qutub Minar in Delhi' },
    { label: 'Forts of Rajasthan' },
    { label: 'Himalayan Adventure Sports' },
    // Additional themes can be added as needed
  ];
  