import { useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';

function BasicDateTimePicker() {
  const [value, setValue] = useState(new Date());
  const [{userDetail}, dispatch] = useStateValue();

  useEffect(()=>{
    console.log(`In DateTimePicker`);
    console.log(value.getDate());
    console.log(value.getMonth());
    console.log(value.getFullYear());
    console.log(value.getHours());
    console.log(value.getMinutes());
    dispatch({type: actionTypes.SET_USER_DETAIL, userDetail:{...userDetail, "dob": `${value.getDate()}/${value.getMonth()+1}/${value.getFullYear()}`, "tob":`${value.getHours()}::${value.getMinutes()}`}});
  },[value])

  return (
    <div>
      <DateTimePicker onChange={setValue} value={value} />
    </div>
  );
}

export default BasicDateTimePicker;