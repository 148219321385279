import React from 'react';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useNavigate } from "react-router-dom"
import Header from './Header';
import Footer from './Footer';

const PrivacyPolicy = () => {

    const navigate = useNavigate();

    return (
        <div>
            <div className="container" style={{padding:"3.9%"}}>
                <Header showLogin={false} />
                <div style={{ marginTop: "2%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "7%" }}>
                            <h1 style={{fontWeight:400}}>TigerChat's Privacy Policy</h1>
                            <br/>
                            <p><i>Last updated: Jan 23rd, 2024</i></p>
                            <br /><br />
                           
                        
                            <p style={{fontSize:"12px"}}>
                            The privacy policy delineates the manner in which <a href="https://www.tigerchat.co.in">https://www.tigerchat.co.in</a>  TigerChat ("we", "us", or "our") gathers, utilizes, discloses, and safeguards your information upon your usage of our products, website, and services (hereinafter referred to collectively as the "Services").
                            </p>
                            <br />

                            <h3>Our Services</h3>
                            <br/>
                        
                            <p style={{fontSize:"12px"}}>
                            TigerChat offers a text generation service. TigerChat can be accessed through{' '}
                                <a href="https://www.tigerchat.co.in">https://www.tigerchat.co.in</a> and its associated internet domains.
                            </p>
                            <br />
                            <br/>
                            <h3>Information TigerChat Collects</h3>
                            <br />
                            <br/>
                            <p style={{fontSize:"12px"}}>
                                Upon visiting our website, specific information may be automatically collected from your device.
                                This information could encompass your IP address, device type, unique device identification numbers,
                                browser type, general geographic location (such as country or city-level location), and
                                additional technical data.
                                Furthermore, we may gather data about your device's interaction with our website,
                                including prompt examples clicked. We employ this information for internal analytics and to enhance the
                                quality and relevance of our service.
                                No user data is used to train any models used for text generation.
                            </p>
                            <br/>
                            <br/>
                            <h3>Analytics Service Providers</h3>
                            <br /><br />
                           
                        
                            <p style={{fontSize:"12px"}}>
                            We may disclose anonymized data regarding usage to analytics service providers who support us in enhancing and refining our services and products. For instance, Google Analytics is one such service provider. Further details can be found at: e.g. Google Analytics <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a> 
                            </p>
                            <br />
                            <br/>
                            <h3>Third Party Services</h3>
                            <br /><br />
                           
                        
                            <p style={{fontSize:"12px"}}>
                            We may utilize website monitoring tools, such as Cloudflare, to acquire anonymized data pertaining to usage patterns. These tools are capable of collecting information including, but not limited to, your IP address, type of device, browser specification, operating system, geographic location, and additional technical details. This information is employed to refine and enhance the quality and applicability of our website for our visitors.
                            </p>
                            <br />

                            {/* Add more sections here... */}

                            <br/>
                            <h2>Contact Us</h2>
                            <br />
                            <p style={{fontSize:"12px"}}>If you have any inquiries regarding this Policy, kindly reach out to us at <a href="mailto:support@chatdragon.ai">support@chatdragon.ai</a>.</p>
                        </div>

                    </div>

                </div>

            </div>
            {/* <Footer  /> */}
        </div>
    );
};

export default PrivacyPolicy;
