import React from 'react';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useNavigate } from "react-router-dom"
import Header from './Header';
import Footer from './Footer';
import { TypeAnimation } from "react-type-animation";
import "../components/Search.css";
import { Button } from '@mui/material';
import AppFooter from './AppFooter';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';

const TigerStore = () => {

    const navigate = useNavigate();
    const [{}, dispatch] = useStateValue();

    const handleClick = (term) => {
        navigate("/query"); 
        window.gtag('event', 'Landing_Prompt_Assistance', {
            event_category: 'Landing_Prompt_Assistance',
            event_label: term,
        });
        dispatch({type:actionTypes.SET_SEARCH_TERM, term: term });
    }

    return (
        <div>
            <div className="container">
                <Header showLogin={false} />
                <div className="" style={{paddingTop:"5%"}}>
                        <TypeAnimation sequence={[  
                            'Top Prompt examples of the day'
                        ]} wrapper="span" speed={25} style={{ fontSize: "1.3em", lineHeight: "2.1rem", display: "inline-block", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} repeat={0} />
                    
            <br/><br/>
            <div className='prompt_assistance_button_group_countainer'>
            <div className='prompt_assistance_button_group' style={{ paddingTop: "0.3%", marginBottom: "6%" }}>
                
            {/* Environmental Awareness */}


            {/* <Button onClick={() => handleClick("Top places to visit in Mumbai")}>
    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {"Top places to visit in Mumbai 🚃"}
    </span>
  </Button> */}

  <Button onClick={() => handleClick("Top street foods in Delhi")}>
    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {"Top street foods in Delhi 🍛"}
    </span>
  </Button>
  <Button onClick={() => handleClick("Stress ko manage kaise kare")}>
                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {"Stress ko manage kaise kare"}
                    </span>
                </Button> 

  <Button onClick={() => handleClick("5 ways to reduce plastic use in daily life 🌍")}>
    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {"5 ways to reduce plastic use in daily life 🌍"}
    </span>
  </Button>
  
  
  
  {/* <Button onClick={() => {navigate("/query"); dispatch({type:actionTypes.SET_SEARCH_TERM, term: "Tips for staying motivated 💪" });}}>
    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {"Tips for staying motivated 💪"}
    </span>
  </Button> */}
  
  
  <Button onClick={() => handleClick("Improving time management skills")}>
    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {"Improving time management skills  ⌛"}
    </span>
  </Button>
  
  {/* <Button onClick={() => handleClick("How to stay active during the day?")}>
    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {"How to stay active during the day? "}
    </span>
  </Button> */}
  <Button onClick={() => handleClick("शुरुआती लोगों के लिए ध्यान का परिचय")}>
    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {"शुरुआती लोगों के लिए ध्यान का परिचय 🧘‍♂️"}
    </span>
  </Button>
  
  <Button onClick={() => handleClick("Reducing your carbon footprint at home")}>
    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {"Reducing your carbon footprint 🏡"}
    </span>
  </Button>
  <Button onClick={() => handleClick("Challenging samay mein motivated kaise rahe?")}>
    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {"Challenging samay mein motivated kaise rahe?"}
    </span>
  </Button>
  <Button onClick={() => handleClick("Best street food places in Mumbai")}>
  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {"Best street food places in Mumbai 🍛"}
  </span>
</Button>
{/* <Button onClick={() => handleClick("How to start an online business in India")}>
  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {"How to start an online business in India"}
  </span>
</Button> */}

<Button onClick={() => handleClick("Healthy Indian meal prep ideas")}>
  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {"Healthy Indian meal prep ideas 🍲"}
  </span>
</Button>
<Button onClick={() => handleClick("Discover trekking spots in the Himalayas")}>
  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {"Discover trekking spots in the Himalayas 🏔️"}
  </span>
</Button>
{/* <Button onClick={() => navigate(`/post?url=${process.env.REACT_APP_IMAGE_URL}`)} style={{background:"lightsteelblue"}}>
                    <span style={{ display: 'flex',  alignItems: 'center', justifyContent: 'center' }}>
                        {"Beta: Generate an HD AI Image"}
                    </span>
                </Button> */}
                <br />
                <br/>
            <TypeAnimation sequence={[
                "AI Generated Taj Mahal",
                500,
                    "Generated by World's most advanced AI",
                    500,
                    "Click the below to try it out"
                ]} wrapper="span" speed={25} style={{ fontSize: "1em", lineHeight: "2.1rem", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} repeat={0} />
            <br/>
            <br/>
            <Button onClick={() => navigate(`/post`)} style={{ padding: 0, border: 'none',  position: 'relative' }}>
                    <img src="/taj.png" alt="Mars" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                    <span style={{ 
                        position: 'absolute', 
                        top: 0, 
                        left: 0, 
                        right: 0, 
                        bottom: 0, 
                        display: 'flex',  
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        color: 'white', // Change as needed based on your image
                        textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)' // Optional, for better readability
                    }}> 
                      
                     </span>
                </Button>
                <br/> <br/>
            </div>
            
        
            <div style={{ margin: "0.5%" }}><i style={{ fontSize: "smaller" }}>*TigerChat is powered by AI, so mistakes are possible. Please review the generated text carefully </i></div>
            <br/>
            <br/>
            <br/>
            
        </div>

                    </div>
            </div>
            <br/>
            <br/>
            <br/>
           <AppFooter showSearch={false}/>
        </div>
    );
};

export default TigerStore;
