import React, {useState, useRef, useEffect } from 'react'
import "./Search.css";
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import {useNavigate} from "react-router-dom"
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import LandingPrompts from "./LandingPrompts";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ClearIcon from '@mui/icons-material/Clear';
import {submitCustomEvent} from "../helper/customevents";
import {Switch} from '@mui/material';
import { Button } from '@mui/material'; //mui button
import GooglePlayBadge from "../othercomponents/GooglePlayBadge";


function Search({write=false, home = false,mode="view", showButtons = false, route=false, placeholder="Ask AI ..", showClear = false, w="93%"}) {
  
  const [{term, loading, streaming, user, headlines, webaccess}, dispatch] = useStateValue();
  const [input, setInput] = useState(term);  
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    // Focus on the textarea element as soon as the component is mounted
    if(inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const defocus_dismisskeyboard = () => {
    if(inputRef.current){
      inputRef.current.blur();
    }
  }

  const search = (e, edit=false) => {
    console.log("search is called");
    e.preventDefault();  

    defocus_dismisskeyboard();
    
    if(input === "" || loading || streaming) 
      return;

    postFunc(edit);
    dispatch({type:actionTypes.SET_SEARCH_TERM, term: input });
    dispatch({type:actionTypes.SET_LOADING, loading: true });
    submitCustomEvent('Input_P', input);
    setInput("");
  }

  const postFunc = (edit) => {
    if(route){
      navigate("/query")      
  } }

  const getHomeStyle = ()=> {
    let style =  {marginBottom:"5%", width:w };
    return home ? {...style, height:"unset", borderRadius: "12px", boxShadow:"2px 4px 12px rgba(0,0,0,.5)"}: {...style, width: "78vw"};
  }

  return (
    <div>
      <form className='search' onSubmit={search}>
          <div className= { "search__input"} style={getHomeStyle()}>
              <SearchIcon style={{opacity:0.65, alignSelf: home  && "flex-start", paddingTop: home && "0.1rem"}} className='search__icon' />
              
              {!home && <input value={input} onChange={e=> setInput(e.target.value)} placeholder={placeholder}/>}
              {home && <textarea rows={4} ref={inputRef} value={input} onChange={e=> setInput(e.target.value)} onKeyDown={(e)=> {
                if(e.key == "Enter"){
                  //e.preventDefault();
                  console.log("enter is pressed")
                  search(e);
                  // setInput("gandhi");
                }
              }} placeholder={write ? "Describe what you'd like to write e.g. Write an out of office email" : "Chat with AI e.g. Give me a list all the Prime Ministers of India and provide a short bio for each"}/>}
              
              {showClear && <ClearIcon style={{opacity:0.65}} onClick={(_)=>setInput("")}/>}
              {
              showButtons 
              &&                          
              <SendIcon type="submit" onClick={search} style={ home && {"color":"black", alignSelf:"flex-end", fontSize:"1.9rem", marginRight:"5px", opacity:"0.9"}}/>
          }
          </div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
         
          {home && <Button style={{ padding: "0px", color: "black", textTransform: "none", display:"flex" }} onClick={async _ => {
                    try {
                     //   const url = "https://tigerchat.co.in";
                        const url = "https://play.google.com/store/apps/details?id=com.tigerchat.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
                        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
                        window.open(whatsappUrl, '_blank');
                        window.gtag('event', 'Share');
                    } catch (_) {
                        console.error("Failed to Copy");
                    }
                }}><img src="/icons/whatsapp.png" alt="WhatsApp" style={{ marginRight: 5, width: "25px", textDecoration: "none" }} />
                <p style={{fontSize:"13px", opacity:"0.7"}}>Share</p> </Button>}

            {/* {home && ( <p style={{fontSize:"small"}}><SecurityIcon style={{marginRight:"4px", marginBottom:"-6px"}}/><i>Secure and Encrypted</i></p>)} */}
            {home && !write && ( <div style={{display:"flex"}}><p style={{fontSize:"small", fontWeight:"500", opacity:"0.7"}}>Web Access<Switch checked={webaccess} onChange={(e)=>{
              dispatch({type: actionTypes.SET_WEB_ACCESS, webaccess: !webaccess});
              console.log("web aaccess event");
              window.gtag('event', "Web_Access");
              
            }} /></p></div>)}
          </div>
          {/* {mode == "landing" (<><GooglePlayBadge/><br/></>)} */}
          {mode == "landing" && (<LandingPrompts write={write} dispatch={dispatch} streaming={streaming} postFunc={postFunc} user={user} headlines={headlines}/>)}      
      </form>
    </div>
    
  )
}

export default Search