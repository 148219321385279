import React from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';

const PlacesAutocomplete = () => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "YOUR_CALLBACK_NAME",
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const [{userDetail}, dispatch] = useStateValue();

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      dispatch({type: actionTypes.SET_USER_GEO, geo:{lat:lat, lon:lng}});
      console.log("📍 Coordinates: ", { lat, lng });
    });
  };

  const autocompleteContainerStyle = {
    position: 'relative',
    display: 'inline-block',
    width: '75vw',
  };

  const inputStyle = {
    width: '100%',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize:"1rem"
  };

  const dropdownContainerStyle = {
    position: 'absolute',
    zIndex: 1000,
    width: '100%',
    maxHeight: '200px', // Limiting the height
    overflowY: 'auto', // Adding a vertical scrollbar
    border: '1px solid #ccc',
    backgroundColor: 'white',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    borderRadius: '4px',
  };

  const dropdownItemStyle = {
    padding: '10px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out',
  };

  return (
    <> <b>Enter Place of Birth </b><br/> 
    <div ref={ref} style={autocompleteContainerStyle}>
      <input
        style={inputStyle}
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Enter Place of Birth e.g. New Delhi"
      />
      {status === "OK" && (
        <div style={dropdownContainerStyle}>
          {data.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <div
                key={place_id}
                style={dropdownItemStyle}
                onMouseDown={handleSelect(suggestion)} // Changed to onMouseDown for better UX
              >
                <strong>{main_text}</strong> <small>{secondary_text}</small>
              </div>
            );
          })}
        </div>
      )}
    </div>
    </>
  );
};

export default PlacesAutocomplete;
