import React from "react";
import Search from '../components/Search';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import "./BasicChat.css"
import { TypeAnimation } from "react-type-animation";
import { modes } from "../mock/PromptAssistanceExamples";
import { useState, useEffect } from "react";
import Header from "./Header";
import AppFooter from "./AppFooter";
import AudioComponent from "../components/AudioComponent";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import BasicDateTimePicker from "./BasicDateTimePicker";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom"
import MobileDateComponent from "./MobileDateComponent";
import MobileTimeComponent from "./MobileTimeComponent";

function BasicChat() {
    const [index, setIndex] = useState(0);
    const [{dob, tob, geo}, dispatch] = useStateValue();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch({ type: actionTypes.SET_STREAMING, streaming: false });
        const interval = setInterval(() => {
            setIndex(u => (u + 1) % modes.length)
        }, 4100);
        return () => clearInterval(interval);
    }, [])

    return (
        <div>
            <div className="container" style={{ padding: "3.9%" }}>
                <Header showLogin={true} />
                <div className="container_body" >
                    {/* <div className="container_copy">
                        <TypeAnimation sequence={[

                            "Enter birth details",
                            300,
                            "Chat with AI Astrologer for free"

                        ]} wrapper="span" speed={2} style={{ fontSize: "1.1em", lineHeight: "2.1rem", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} />
                    </div> */}
                   
                    
                    <br />
                    <PlacesAutocomplete />
                    <br />
                    {/* <BasicDateTimePicker/> */}
                    {/* <MobileDatePicker value={dateValue} onChange={(newDateValue) => setDateValue(newDateValue)} /> */}
                    <MobileDateComponent />
                    <br />
                    <MobileTimeComponent />
                    <br />
                    <Button disabled = {dob == '' || geo.lat == 0 || geo.lon == 0} style={{background:"#FFD814", color:"black", height:"50px", borderRadius:"8px", boxShadow:"0 2px 5px 0 rgba(213,217,217,.5)", textTransform:"none", fontSize:"1rem"}} onClick={(_) => {
                        dispatch({ type: actionTypes.SET_SEARCH_TERM, term: "AI Astro" });
                        dispatch({ type: actionTypes.SET_LOADING, loading: true });
                        navigate("/query");
                    }}>Chat with Vedic Astrologer</Button>
                     <br />
                     <div style={{margin:"auto", textAlign:"center"}}>
                        <img src="/vedic.webp" style={{ width: "80%", margin:"1%" }} />
                    </div>
                      <br /> <br /> <br /> <br /> <br />

                    {/* <Search home={true} showButtons={true} mode={"landing"} placeholder={""} route={true} style={{ fontWeight: 400 }} />
                     */}
                </div>
            </div>
            <AppFooter showHome={false} showSearch={false} showNumerology={false} showWrite={true} />
        </div>
    );
}

export default BasicChat;