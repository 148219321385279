import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { forwardRef } from 'react';
import AudioPlayer from "react-h5-audio-player"; 
import "react-h5-audio-player/lib/styles.css";
import { useRef } from 'react';
import { TypeAnimation } from "react-type-animation";
import "./Search.css";
import { useEffect } from 'react';
import { actionTypes } from '../statemanagement/reducer';
//currentIdPlaying={currentIdPlaying} setCurrentIdPlaying={setCurrentIdPlaying}
const SnapCard = forwardRef(({handleClick, voice="", video="", name, image, source, id="0",showAudio=true, showName=true, question="", summary="", dispatch}, ref) => {

  const playerRef = useRef(null); 
  const cardRef = useRef(null);

  const togglePlayPause = () => {

    const player = playerRef.current;
    if (player) {
        if (player.audio.current.paused) {
           //user wants to play, let the others know of this
          //  setCurrentIdPlaying(id);
            // test("id");
            player.audio.current.play();
        } else {
            player.audio.current.pause();
        }
    }
};

//   useEffect(()=>{

//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry)=>{

//           console.log(entry);
//           if(entry.isIntersecting){
//             // console.log("entry is intersecting");
//             //console.log(`id: ${id} is intersecting`);
//             //console.log(`has interacted is: ${hasInteracted}`)
//             console.log(entry);
//             // if(interacted){
//             //  // console.log(`Toggling PLay Pause of id: ${id}`)
//             //   togglePlayPause();
//             // }
            
//             // console.log(entry.intersectionRatio);
//           }
//         })
//       },
//       {
//         threshold:0
//       }
//     );


//     if(!!cardRef && cardRef.current){ //current has been set to the card
//       observer.observe(cardRef.current); //observe
//     }

//     return () => {
//       if(!!cardRef && cardRef.current){
//         observer.unobserve(cardRef.current);
//       }
//     }

//   },[id, interacted])

 


//  /**
//    * const cardRef = {
//    *  "current": "<div><Card>..<div/>"
//    * }
//    */
//"2px 2px 2px 2px rgba(0,0,0,0), 2px 2px 2px 2px rgba(0,3,1,0.14), 2px 2px 2px 2px rgba(0,0,0,0.12)"

  return (
           
     <Card onClick={() => {
      if(id == "0"){
        dispatch({type: actionTypes.SET_WEB_ACCESS, webaccess: true});
        handleClick(name);
      }else{
        togglePlayPause();
      }
     }} ref={ref} sx={{ boxShadow:"none", fontFamily:"'Playfair Display', serif", height:"80vh" }}>
      <CardActionArea>
       {image != "" && <CardMedia
          component="img"
          image={image}
          alt="green iguana"
        /> }
        {video != "" && <CardMedia component="video" src='/sleep.mp4'/> }
        
       
        <CardContent>
          {showName && (<Typography gutterBottom variant="h5" component="div" sx={{ fontFamily:"'Playfair Display', serif"  }}>
            {name}
          </Typography>)}
          { summary != "" && 
          <><br/><Typography gutterBottom variant="body" component="h3" sx={{ fontFamily:"'Playfair Display', serif" }}>
            <i>Summary Generated with AI</i>
          </Typography><br/></>
          }
          { summary != "" && <Typography gutterBottom variant="body" component="div" sx={{ fontFamily:"'Playfair Display', serif", fontSize:"16px"  }}>
            {summary}
          </Typography>}
             
          <br/>

          {(!!source && source != "" && showAudio) && <Typography gutterBottom variant="body" component="div" sx={{ fontFamily:"'Playfair Display', serif"  }}>
            Source: {source}
          </Typography>}
          <br/>
         
          <br/>
          
          {voice!= "" &&
          <Typography gutterBottom variant="body" component="div" sx={{ fontFamily:"'Playfair Display', serif", fontSize:"16px", fontWeight:"bold"  }}>
 
            <TypeAnimation sequence={[
                    `Voice: ${voice}`
                ]} wrapper="span" speed={1} style={{ fontSize: "1.0em", lineHeight: "2.1rem", color: "rgb(52, 52, 52)", fontWeight: "bold", opacity: "0.9" }}  />
          </Typography>}

        </CardContent>
      </CardActionArea>
      <CardActions ref={cardRef} style={{ justifyContent: "space-between" }}>

        
      { (id != "0") && showAudio && <AudioPlayer ref={playerRef} layout="stacked" showFilledVolume={false} showJumpControls={false} showSkipControls={false} style={{boxShadow:"none"}} onPlay={_ => {
                window.gtag('event', 'audio');
            }} 
            src={`https://tigerchatcontent.blob.core.windows.net/tigerchat/${id}.mp3`} />}

          {(id == "0") && (question!="") && (          
    
          <div style={{margin:"auto"}}> 
              <Button onClick={() => handleClick(question)} style={{background:"whitesmoke", color:"#1d1d1f", textTransform:"inherit"}}>
                Ask AI - {question}
              </Button>
           </div>
           
           )}
           
      </CardActions>      
    </Card>
  );
})

export default SnapCard;



