import React, { useEffect, useRef, useState } from 'react';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import useAIService from "../helpers/useAIService";
import Search from '../components/Search';
import "./Results.css";
import "./BasicChat.css"
import Refine from "../components/AskFollowUp";
import { useNavigate } from "react-router-dom"
import AtomicSpinner from 'atomic-spinner';
import { Button, CardContent, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import "./BasicChat.css"

function Astrobot() {

    const [{ term, followup, conversationHistory, loading, streaming, nextQuestion, chatMode, user, subscription, mode }, dispatch] = useStateValue();
    const refToScrollableDiv = useRef(null);
    useAIService(term, followup, refToScrollableDiv, user, subscription);

    const displayConversationHistory = JSON.parse(JSON.stringify(conversationHistory));

    const [autoScroll, setAutoScroll] = useState(true);

    const placeholders = [
        "Ask AI e.g, Stock trading overview",
        "Ask AI e.g, Is milk part of Keto diet?",
        "Ask AI e.g, Explain airplane turbulence",
        "Ask AI e.g, What is the NIFTY 50?",
        "Ask AI e.g, Write happy birthday greeting",
        "Ask AI e.g, Write OOO email to my manager",
        "Ask AI e.g, Keto meal recipe for breakfast",
        "Ask AI e.g, Cheese cake recipe",
        "Ask AI e.g, Plan a 3-day trip to Goa",
        "Ask AI e.g, Prepare GMAT study plan",
        "Ask AI e.g, Plan resistance training workout",
        "Ask AI e.g, Prepare GRE study plan",
        "Ask AI e.g, Prepare CBSE study plan",
        "Ask AI e.g, Plan a 3-day trip to Goa",
        "Ask AI e.g, Simplified stock trading explanation",
        "Ask AI e.g, Simplified options trading explanation",
        "Ask AI e.g, Retro-style game concept ideas",
        "Ask AI e.g, Halloween dress selection ideas",
        "Ask AI e.g, Generate 3 ideas for startups"
    ];


    const [index, setIndex] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {

        const interval = setInterval(() => {
            setIndex(u => (u + 1) % placeholders.length)
        }, 6000);
        if (term !== "")
            clearInterval(interval);
        return () => clearInterval(interval);
    }, [term])

    const handleCopyClick = async (u) => {
        try {
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(u, 'text/html');
            const textToCopy = parsedHtml.body.textContent || '';
            await navigator.clipboard.writeText(textToCopy);
            console.log("paste and verify");
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };


    useEffect(() => {

        if (conversationHistory.length < 2) return;

        if (!refToScrollableDiv.current) return;

        if (autoScroll && streaming) {
            const { scrollHeight } = refToScrollableDiv.current;
            refToScrollableDiv.current.scrollTo(0, scrollHeight);
        }
        if (followup.length > 0) {
            const { scrollHeight } = refToScrollableDiv.current;
            refToScrollableDiv.current.scrollTo(0, scrollHeight);
        }

    }, [conversationHistory, autoScroll, followup]);


    useEffect(() => {
        if (term.length > 0 || followup.length > 0) setAutoScroll(true);
    }, [term, followup])

    useEffect(() => {
        const scrollContainer = refToScrollableDiv.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("mousedown", () => setAutoScroll(false));
            scrollContainer.addEventListener("touchstart", () => setAutoScroll(false));
            scrollContainer.addEventListener("click", () => setAutoScroll(false));
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener("mousedown", () => setAutoScroll(false));
                scrollContainer.removeEventListener("touchstart", () => setAutoScroll(false));
                scrollContainer.removeEventListener("click", () => setAutoScroll(false));
            }
        }

    }, [])

    const focusButtonStyle = () => {
        return { borderRadius: "18px", opacity: "0.9", marginLeft: "5px", marginRight: "5px", boxShadow: "2px 4px 12px rgba(0,0,0,.5)", background: "black", paddingLeft: "16px", marginRight: "8px", fontSize: "small", color: "white" };
    }

    return (
        <div className='resultsPage'>
            {
                <div className='resultsPage__header'>
                    <div style={{ display: "flex", alignItems: "baseline" }} className='resultsPage__header__prompt__entry'>
                        <AllInclusiveIcon style={{ marginRight: "2.5%" }} onClick={(_) => { dispatch({ type: actionTypes.RESET_STATE });; navigate("/"); }} />

                        {!chatMode && <Search w={"97%"} placeholder={"Ask AI anything"} showClear={true} />}
                        {chatMode && <h1 onClick={(_) => { dispatch({ type: actionTypes.RESET_STATE });; navigate("/query"); }} style={{ fontWeight: 500, opacity: "0.9", fontFamily: "'Roboto', sans-serif" }}>ChatDragon.ai</h1>}
                    </div>
                </div>
            }
            <div className='resultsPage__body' ref={refToScrollableDiv}>
                <div className='resultsPage__ai__response'>
                    {(
                        displayConversationHistory
                            .filter(u => u.role === "assistant" || u.role === "user")
                            .map((u, _) => {
                                if (u.role == "user") {
                                    return (<div>
                                        <h2 style={{ padding: "3%", paddingBottom: "3%" }}>{u.content}</h2>
                                    </div>)
                                }
                                return (<div className='ai__display'>
                                    <div className='ai__display__assistant'>
                                        {
                                            <>

                                                <div style={{ whiteSpace: "pre-line", padding: "3%" }} dangerouslySetInnerHTML={{ __html: u.content }}></div>

                                                {!streaming && mode == "write" && <Button onClick={async (e) => {
                                                    await handleCopyClick(u.content);
                                                    window.gtag('event', 'Copy');
                                                }} style={{ marginLeft: "80%" }} startIcon={<ContentCopyIcon />}>Copy</Button>}

                                                <hr style={{ marginTop: "1%", opacity: "0.3" }} />
                                            </>
                                        }
                                    </div>
                                </div>)
                            })
                    )}

                    {loading && <div className='ai__display'>
                        <div className='ai__display__assistant'> <div className='resultsPage__ai__response__spinner' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <AtomicSpinner style={{ width: '250px', height: '250px' }} />
                        </div></div></div>
                    }


                </div>

            </div>
            <div className='resultsPage__footer'>
                <Refine refToScrollableDiv={refToScrollableDiv} showQuestions={true} />
            </div>

        </div>
    );
}

export default Astrobot;

