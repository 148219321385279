import React from 'react';


// const GooglePlayBadge = () => {
//   return (
//     <div onClick={(u)=> {
//         window.gtag('event', "Store");
//     }} className="google-play-badge">
//       <a href='https://play.google.com/store/apps/details?id=com.tigerchat.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' 
//          target="_blank" 
//          rel="noopener noreferrer" 
//          aria-label="Download TigerChat App on Google Play">
//         <img style={{width:"180px", height:"70px"}}
//           alt='Get it on Google Play' 
//           src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
 
//         />
//       </a>

//     </div>
//   );
// }

// export default GooglePlayBadge;


const GooglePlayBadge = () => {
  return ( <></>
    // <div onClick={(u)=> {
    //     window.gtag('event', "Store");
    // }} className="google-play-badge" style={{textAlign: 'center', maxWidth: '250px', margin: '0 auto'}}>
    //   <a href='https://play.google.com/store/apps/details?id=com.tigerchat.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' 
    //      target="_blank" 
    //      rel="noopener noreferrer" 
    //      aria-label="Download TigerChat App on Google Play">
    //     <img 
    //       alt='Get it on Google Play' 
    //       src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
    //       style={{width: '100%', height: 'auto', border: '0'}}
    //     />
    //   </a>
    //   {/* Responsive legal attribution text */}
    //   <p style={{fontSize: '10px', color: '#666', marginTop: '4px'}}>
    //     Google Play and the Google Play logo are trademarks of Google LLC.
    //   </p>
    // </div>
  );
}

export default GooglePlayBadge;
