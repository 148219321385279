import React, { useEffect, useState, useRef } from 'react'
import "./AskFollowUp.css";
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import ShortTextIcon from '@mui/icons-material/ShortText';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LinkIcon from '@mui/icons-material/Link';
import ClearIcon from '@mui/icons-material/Clear';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ArticleIcon from '@mui/icons-material/Article';
import LanguageIcon from '@mui/icons-material/Language';
import AirlineSeatFlatAngledIcon from '@mui/icons-material/AirlineSeatFlatAngled';
import ReplayIcon from '@mui/icons-material/Replay';
import AtomicSpinner from 'atomic-spinner';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import {useNavigate} from "react-router-dom"

import { Button } from '@mui/material';
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import {submitCustomEvent} from "../helper/customevents";

function AskFollowUp({ refToScrollableDiv, showQuestions = false}) {

  const [{followup, conversationHistory, loading, streaming, nextQuestion, nextQuestionLoading}, dispatch] = useStateValue();
  const [input, setInput] = useState(followup);  
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const askfollowup = (e) => {
  
    console.log(`LOADING: ${loading}`);
    console.log(`STREAMING: ${streaming}`);

    if(loading || streaming)
      return;
    if(!!e)
      e.preventDefault();
    if(input === "")
      return;
    
      if(inputRef.current){
        inputRef.current.blur();//dismiss keyboard
      }
    
    
    // if(!!refToScrollableDiv && !!refToScrollableDiv.current){
    //   refToScrollableDiv.current.scrollTo({
    //     top: 0,
    //     behavior: "smooth"
    //   })
    // }
    console.log("Calling Updating State");
    //updateState();
    dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: input });
    setInput("");
  //   window.gtag('event', 'Refine_Input', {
  //     event_category: 'Refine_Input',
  //     event_label: input, 
  // });
  submitCustomEvent('Refine_Input', input);
  }

   const isStart = () => conversationHistory.filter(u=>u.role == "user").length == 0;
  // const isStart = () => conversationHistory.length == 0;

  // useEffect(()=>{
  //   if(!!nextQuestion && nextQuestion.length>0) setInput(nextQuestion)
  // },[nextQuestion])

  return (
    <div>
      
{/*       
      <div style={{margin:"auto"}}>
        {nextQuestion!="" && <Button className='auto__gen__button' onClick={(_)=> {
          dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: nextQuestion });
          window.gtag('event', 'auto_gen', {
            event_category: 'auto_gen',
            event_label: nextQuestion, 
        });
        }}>{nextQuestion}</Button>} 
      </div> */}
      
    {/** style={{bottom:"5px", position:"fixed", padding:"2.5%"}}  height:"250px", padding:"2.5%",   height:"34vh",*/}
    {/** paddingLeft:"3vw", paddingRight:"3vw",paddingBottom:"1vh", */}
    <form className='askfollowup' style={{position:"fixed", padding:"11px", bottom:"0", background:"white", zIndex:1000, textAlign:"center"}} onSubmit={askfollowup}>
    <div className='svg-container' style={{display:"flex", justifyContent:"center" }}>
        {nextQuestionLoading && showQuestions && <AtomicSpinner />}
        {/* {!nextQuestionLoading && <div style={{height:"20px"}} />} */}
        {/* <AtomicSpinner/> */}
      </div>
    <div style={{display:"flex", justifyContent:"center"}}>
        {nextQuestion!="" && showQuestions && <Button className='auto__gen__button' onClick={(_)=> {
          dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: nextQuestion });
          window.gtag('event', 'auto_gen', {
            event_category: 'auto_gen',
            event_label: nextQuestion, 
        });
        }}>{nextQuestion}</Button>} 
      </div>
           {/** style={{display: (conversationHistory.filter(u => u.role === "user" || u.role === "assistant").length > 0) && !loading ? "" : "none"}} */}
        <div className='askfollowup__input'>
            {/* <SearchIcon className='askfollowup__icon' /> */}
            <input ref={inputRef} value={input} onChange={e=> setInput(e.target.value)} placeholder={"Ask More.."}/>
            <SendIcon style={{opacity: 0.8}} onClick={askfollowup}/>
            {/* <MicIcon/> */}
            
        </div>
        <Button style={{display:"none"}} type='submit' onClick={askfollowup} />
        <div className='refine_button_group' style={{minWidth:"80vw !important", maxHeight:"14vh"}}>
         <div className='refine_button_row'>
           <Button className='refine_button' startIcon={<BorderClearIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Simplify it, rewrite by making it very simple" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Simplify", 
            } ); }}>Simplify</Button>
            {/* <Button className='refine_button' startIcon={<ReplayIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Rephrase it" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Rephrase", 
            } ); }}>Retry</Button> */}
              <Button className='refine_button' startIcon={<ArticleIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Add more detail" }); window.gtag('event', 'Refine_PA', {
                  event_category: 'Refine_PA',
                  event_label: "More Detail", 
              });}}>Elaborate</Button>
              {/* <Button className='refine_button' startIcon={<ShortTextIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "rewrite to make the output much shorter" }); window.gtag('event', 'Refine_PA', {
                  event_category: 'Refine_PA',
                  event_label: "Shorten it", 
              });}}>Shorten</Button> */}
                <Button style={{fontSize:"14px !important"}} className='refine_button' startIcon={<LanguageIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "rewrite in Hindi" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "To Hindi", 
            });}}>हिंदी</Button>
        </div>

        {/* <div className='refine_button_row'> */}
        {/* <Button className='refine_button' startIcon={<LinkIcon/>} onClick={ (_) => {
              window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Large Font", 
            });  
            dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "LINKS" });
          }}>Internet Links</Button> */}
          {/* <Button className='refine_button' startIcon={<FormatListBulletedIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Bulletize it" }); window.gtag('event', 'Refine_PA',  {
                  event_category: 'Refine_PA',
                  event_label: "Bulletize", 
              });}}>Bulletize</Button>
                <Button className='refine_button' startIcon={<ShortTextIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "rewrite to make the output much shorter" }); window.gtag('event', 'Refine_PA', {
                  event_category: 'Refine_PA',
                  event_label: "Shorten it", 
              });}}>Shorten</Button>
                
                <Button className='refine_button' startIcon={<AddReactionIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Add emoji" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Add emoji", 
            });}}>+emoji</Button> */}
        {/* </div> */}
       
        <div className='refine_button_row'>
       
              <Button className='refine_button' startIcon={<TextIncreaseIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "rewrite all text in output with font-size=20px" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Large Font", 
            });}}>Large Font</Button>
            {/* <Button className='refine_button' startIcon={<AirlineSeatFlatAngledIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "rewrite by making it a bit informal" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Large Font", 
            });}}>Make it Informal</Button> */}
            <Button className='refine_button' startIcon={<FormatListBulletedIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Bulletize it" }); window.gtag('event', 'Refine_PA',  {
                  event_category: 'Refine_PA',
                  event_label: "Bulletize", 
              });}}>Bulletize</Button>
            {/* <Button className='refine_button' startIcon={<AirlineSeatFlatAngledIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "Return to the user search links from google.com context" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Large Font", 
            });}}>Get Search Links</Button> */}
            
            <Button className='refine_button' startIcon={<AllInclusiveIcon/>} onClick={ (_) => {
              window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Back", 
            });             
            dispatch({type:actionTypes.RESET_STATE}); navigate("/"); dispatch({type: actionTypes.UPDATE_HEADLINES, headlines: []});
          }}>Home</Button>
             {/* <Button className='refine_button' startIcon={<LanguageIcon/>} onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "rewrite in Hindi" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "To Hindi", 
            });}}>Rewrite in Hindi</Button> */}
          </div>
        </div>
      
        
    </form>

    {/* {nextQuestion!="" && 
      
      //last
      (_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.UPDATE_CONVERSATION_HISTORY, enrichedConversationHistory: [] }); dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "" }); dispatch({type:actionTypes.SET_LOADING, loading: false });

      <div>
        <div>Related</div>
      <Button onClick={(_)=>{if (streaming || isStart(conversationHistory)){return;} dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: {nextQuestion} }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Simplify", 
            } ); }}>{nextQuestion}</Button></div> } */}

    </div>
  )
}

export default AskFollowUp