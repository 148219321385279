const Disclaimer = ({hindi=true}) => {
    return (
        <>
            <div style={{ margin: "3%" }}><i style={{ fontSize: "smaller" }}>*Disclaimer: TigerChat is powered by AI, prone to errors. Information offered is for general purposes and should not be seen as medical, legal, or professional advice. Consult appropriate professionals for specific concerns. Please review the generated text and audio carefully </i></div>
            <br />
            {hindi &&
            (<><div style={{ margin: "3%" }}><i style={{ fontSize: "smaller" }}>*अस्वीकरण: टाइगरचैट एआई द्वारा संचालित है, गलतियाँ हो सकती हैं। प्रदान की गई जानकारी सामान्य उद्देश्यों के लिए है और इसे चिकित्सा, कानूनी, या व्यावसायिक सलाह के रूप में नहीं देखा जाना चाहिए। विशिष्ट चिंताओं के लिए उचित पेशेवरों से परामर्श लें। कृपया उत्पन्न पाठ और ऑडियो की सावधानीपूर्वक समीक्षा करें। </i></div>
            <br /></>)}
        </>

    );
};

export default Disclaimer;

