import { useEffect, useState } from 'react';
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import dayjs from 'dayjs';

const MobileTimeComponent = () => {
  // const [value, setValue] = useState(new Date());
  //dispatch({type: actionTypes.SET_USER_DETAIL, userDetail:{...userDetail, "dob": `${dateValue.date()}/${dateValue.month()}/${dateValue.year()}`, "tob":`${value.getHours()}::${value.getMinutes()}`}});
  const [timeValue, setTimeValue] = useState(dayjs('2024-03-28T15:30'));
  const [_, dispatch] = useStateValue();

  useEffect(()=>{
    //console.log(`In MobileDateComponent`);
    //console.log(timeValue.hour());
    //console.log(timeValue.minute());
    //dispatch({type: actionTypes.SET_USER_DETAIL, userDetail:{...userDetail, "tob":`${timeValue.hour()}:${timeValue.minute()}`}});
    dispatch({ type: actionTypes.SET_USER_TOB, tob: `${timeValue.hour()}:${timeValue.minute()}`});
  },[timeValue])

  return (
    <div>
         <b>Enter Birth Time </b><br/>  <br/>
        <MobileTimePicker value={timeValue} onChange={(newTimeValue) => setTimeValue(newTimeValue)} />
    </div>
  );
}

export default MobileTimeComponent;