import React from "react";
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import "./BasicChat.css"
import { modes } from "../mock/PromptAssistanceExamples";
import { useState, useEffect } from "react";
import Header from "./Header";
import AppFooter from "./AppFooter";
import BasicDateTimePicker from "./BasicDateTimePicker";


function Numerology() {
    const [index, setIndex] = useState(0);
    const [{}, dispatch] = useStateValue();


    useEffect(() => {
        dispatch({ type: actionTypes.SET_STREAMING, streaming: false });
        const interval = setInterval(() => {
            setIndex(u => (u + 1) % modes.length)
        }, 4100);
        return () => clearInterval(interval);
    }, [])

    return (
        <div>
           
            <div className="container">
                <Header showLogin={true} />

                <div className="container_body" style={{paddingTop: "5%"}}>

                    Add a DateTime picker here
                    <BasicDateTimePicker/>
                </div>
            </div>
            <AppFooter showHome={false} showSearch={false} showNumerology={true} />
        </div>
    );
}

export default Numerology;