import React, { useEffect } from 'react';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useNavigate } from "react-router-dom"
import Header from './Header';
import Footer from './Footer';
import { TypeAnimation } from "react-type-animation";
import "../components/Search.css";
import { Button } from '@mui/material';
import AppFooter from './AppFooter';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import BasicSelect from '../othercomponents/BasicSelect';
import AtomicSpinner from 'atomic-spinner';
import { useState } from 'react';
import CardComponent from '../components/CardComponent';
import axios from "axios";
import ComboBox from '../components/ComboBox';

const Post = () => {

    const navigate = useNavigate();
    const [{ }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [selectedGenre, setSelectedGenre] = useState("");
    const [post, setPost] = useState(null);

    useEffect(()=>{
        setPost({url: "/mars.png"}); 
        setShowResult(true);
        setLoading(false);
    },[])
    // useEffect(()=>{
    //     const queryString = window.location.search;

    //     // Create a URLSearchParams object from the query string.
    //     const urlParams = new URLSearchParams(queryString);

    //     // Use the .get() method to retrieve the value of the 'url' parameter.
    //     const extractedUrl = urlParams.get('url');

    //     // Check if the URL exists and then use it as needed.
    //     if (extractedUrl) {
    //         console.log('Extracted URL:', extractedUrl);
    //         //https://oaidalleapiprodscus.blob.core.windows.net/private/org-hERr9pHTRIgqUXhcwiYX3KqP/user-sQV3loALd2OluRBJqfCukCNE/img-qgBoHb75F3JupUjze7kf3ATz.png?st=2024-03-04T10%3A45%3A18Z&se=2024-03-04T12%3A45%3A18Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-03-04T04%3A39%3A53Z&ske=2024-03-05T04%3A39%3A53Z&sks=b&skv=2021-08-06&sig=YyJBSnwWost5Rl5ffPMxt/TVkCCHzxRi8w58rM7rezM%3D
    //        // setPost({url:"https://oaidalleapiprodscus.blob.core.windows.net/private/org-hERr9pHTRIgqUXhcwiYX3KqP/user-sQV3loALd2OluRBJqfCukCNE/img-qgBoHb75F3JupUjze7kf3ATz.png?st=2024-03-04T10%3A45%3A18Z&se=2024-03-04T12%3A45%3A18Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-03-04T04%3A39%3A53Z&ske=2024-03-05T04%3A39%3A53Z&sks=b&skv=2021-08-06&sig=YyJBSnwWost5Rl5ffPMxt/TVkCCHzxRi8w58rM7rezM%3D"});
    //        setPost({url: extractedUrl}); 
    //        setShowResult(true);
    //         setLoading(false);
    //         // You can now use `extractedUrl` as a regular string in your application.
    //         //return extractedUrl;
    //     } else {
    //         console.log('URL parameter is not available.');
    //         //return null;
    //     }

    // },[])

    useEffect(()=> {
        
        const fetchPost = async (selectedGenre) => {
            setLoading(true);
            setShowResult(false);
            const result = await axios.get(`https://infolitsearchservice.azurewebsites.net/generatePost/${selectedGenre}`);
            console.log("");
            console.log(result);
            setPost({url:result.data.url});
            setLoading(false);
            setShowResult(true);

            // const newUrl = new URL(window.location);
            // newUrl.searchParams.set('url', result.data.url);
            // window.history.pushState({}, '', newUrl);
        }

        if(selectedGenre != ""){
            console.log("calling fetch post");
            window.gtag('event', 'Image');
            fetchPost(selectedGenre);
        }
    }, [selectedGenre])


    return (
        <div>
            
            <div className="container">
                <Header showLogin={false} />
                <div className="" style={{ paddingTop: "5%", textAlign: "center" }}>
                    <TypeAnimation sequence={[
                        'Create an AI Generated HD Image',
                    ]} wrapper="span" speed={25} style={{ fontSize: "1.3em", lineHeight: "2.1rem", display: "inline-block", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} repeat={0} />

                    <br />  <br/>
                    <div className='prompt_assistance_button_group_countainer'>
                        <div className='prompt_assistance_button_group' style={{ paddingTop: "0.3%", marginBottom: "6%" }}>

                            {/* <BasicSelect trigger={(type)=>{
                                //console.log(`In Parent, Type selected is: ${type}`);
                                setSelectedGenre(type);
                            }} /> */}

                            <ComboBox trigger={(type)=> {
                                console.log(`Selected genre is: ${type}`);
                                setSelectedGenre(type)
                            }}/>
                            { loading && <AtomicSpinner style={{ width: '250px', height: '250px'}} />}
                            <br/>
                            {showResult && !loading && <CardComponent id={100}  
                                            dispatch={()=>{}} handleClick={(_)=>{}}  
                                            key={"100"} showAudio={false} showName={false}
                                            source={"Source"} 
                                            name={"Name"} 
                                            body={"Body"} 
                                            image={post.url} />}
                                            <br/><br/>
                            {/* {showResult && !loading && <Button>Create Post</Button>} */}
                        </div>


                    </div>

                </div>
            </div>
            <AppFooter showPost={false} showHome={false} shareUrl={window.location.href} showSearch={false} />
        </div>
    );
};

export default Post;
